import React from "react";

const extractText = (str, query) => {
  const n = str.toUpperCase();
  const q = query.toUpperCase();
  const x = n.indexOf(q);
  if (!q || x === -1) {
    return { founded: str }; // bail early
  }
  const l = q.length;

  return {
    front: str.substr(0, x),
    founded: str.substr(x, l),
    ended: str.substr(x + l),
  };
};

const typeInfo = (value) => {
  switch (value.object_type) {
    case "State":
      return { name: "Provinsi", location: `Provinsi di Indonesia` };

    case "District":
      return {
        name: "Kota/Kabupaten",
        location: `Kota/Kabupaten di ${value.district.state.state_name}, Indonesia`,
      };

    case "Area":
      return {
        name: "Kecamatan",
        location: `Kecamatan di ${value.area.district.district_name}, ${value.area.district.state.state_name}, Indonesia`,
      };

    case "Kata Kunci":
      return {
        name: "Kata Kunci",
        location: value.object_name,
      };

    default:
      break;
  }
};

const RecomendationItemText = (props) => {
  const { value, currentInput, onClick } = props;
  const explodedText = extractText(value.object_name, currentInput);
  const areaInfo = typeInfo(value);

  return (
    <div
      className="cursor-pointer px-4 py-2 d-flex align-items-center"
      onClick={onClick}
    >
      <div className="flex-grow-1 d-flex flex-column gap-2">
        {areaInfo.name !== "Kata Kunci" && (
          <div className="d-flex" style={{ whiteSpace: "pre-wrap" }}>
            {explodedText.front}
            <strong>{explodedText.founded}</strong>
            {explodedText.ended}
          </div>
        )}

        <p className="mb-0" style={{ fontSize: "14px" }}>
          {areaInfo.location}
        </p>
      </div>

      <div
        className="badge fw-normal h-100 px-3 py-2 rounded-pill"
        style={{ color: "#787d9c", background: "#eaebf0" }}
      >
        {areaInfo.name}
      </div>
    </div>
  );
};

export default RecomendationItemText;
