import React from "react";
import dynamic from "next/dynamic";
import CurrencyFormat from "../CurrencyFormat";
import Link from "next/link";
import Image from "next/image";
import { listingUrlFromDetail } from "../../../utils/listing";
import WishListButton from "../WishListButton";
import { TRACK_LISTING_VIEW_DETAIL } from "../../../utils/trackEvent";
import { useQuery } from "@apollo/client";
import { VIEW_LISTING_ALLOWANCE } from "../../../action/user";
import { useStateContext } from "../../../context";
import { handleSetCountView, kFormatter } from "../../../utils";
import { useRouter } from "next/router";

const ListingSlider = dynamic(() => import("../../ListingSlider"), {
  ssr: false,
  loading: () => <div className="img-lazy carousel-placeholder"></div>,
});
const ListingAction = dynamic(() => import("../../ListingAction"), {
  ssr: false,
});

const ListingItem = (props) => {
  const {
    district: { district_name = "" },
    bathroom,
    bedroom,
    media,
    product_name,
    category,
    date,
    id,
    user,
    area,
    price,
    state,
    onClick,
    searchTrackingId,
    searchTrackingQuery,
    clickCount,
    setClickCount,
  } = props;

  const listingUrl = listingUrlFromDetail(props);
  const [ctxData, setState] = useStateContext();
  const loggedInUser = ctxData.user;

  const router = useRouter();

  const viewAllowance = clickCount < 3;

  const redirectingToListing = () => {
    TRACK_LISTING_VIEW_DETAIL(id);
    window.open(`/${listingUrl}`);
  };

  const onClickListingDetail = () => {
    const isLogin = !!loggedInUser;
    if (!isLogin) {
      if (viewAllowance) {
        redirectingToListing();
        handleSetCountView(clickCount);
        setClickCount();
      } else {
        setState({ ...ctxData, loginModal: true });
        router.push(
          `/login?continue=${encodeURIComponent(window.location.href)}`
        );
      }
    } else {
      redirectingToListing();
    }
  };

  return (
    <div
      className="card text-decoration-none mt-1 mt-sm-2"
      onClick={() => {
        onClick(props);
      }}
    >
      <div
        style={{
          position: "relative",
          display: "block",
          overflow: "hidden",
        }}
      >
        <div className="card-img h-100 d-flex justify-content-center">
          <ListingSlider
            listingId={id}
            medias={media}
            productName={product_name}
          />

          <div style={{ display: "flex" }}>
            <div
              style={{
                position: "absolute",
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                height: "100%",
                width: "100%",
              }}
            >
              <div
                style={{
                  position: "absolute",
                  bottom: "5%",
                  left: "5%",
                  backgroundColor: "#00000080",
                  borderRadius: "5px",
                  padding: "5px",
                }}
              >
                <p className="mb-0 text-white d-flex gap-2 align-items-center">
                  <i
                    className="bi bi-clock"
                    style={{ textShadow: "1px 1px #000000" }}
                  ></i>
                  <span style={{ textShadow: "1px 1px #000000" }}>
                    {timeSince(date)}
                  </span>
                </p>
              </div>

              <div
                style={{
                  position: "absolute",
                  top: "5%",
                  right: "5%",
                  hover: "right",
                  zIndex: 99,
                  backgroundColor: "#FFFFFF80",
                  width: "40px",
                  height: "40px",
                  paddingRight: "7px",
                  display: "inline-flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "10px",
                }}
              >
                <WishListButton
                  listingUser={user}
                  listingId={id}
                  secondary={media.length > 0}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card-body d-flex flex-column" style={{ height: "130px" }}>
        <h2
          className="fs-5 text-truncate"
          style={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            WebkitLineClamp: 2,
            maxHeight: "3rem",
            textOverflow: "ellipsis",
            whiteSpace: "normal",
            msOverflowStyle: "ellipsis",
            width: "100%",
            content: "…",
            cursor: "pointer",
            color: "#21314A",
            fontWeight: "500",
          }}
          onClick={onClickListingDetail}
        >
          {product_name ? product_name : "Valle Verde"}
        </h2>

        <div className="d-flex align-items-center gap-1 mb-2">
          <i className="bi bi-geo-alt-fill text-danger"></i>
          <h6
            className="card-title mb-0 text-truncate"
            style={{ fontSize: "15px" }}
          >
            <a href={`/listing?search=${district_name}`}>
              <span className="text-secondary">{district_name}</span>
            </a>
            ,{" "}
            <a href={`/listing?search=${area?.area_name}`}>
              <span className="text-secondary">{area?.area_name}</span>
            </a>{" "}
            ,{" "}
            <a href={`/listing?search=${state?.state_name}`}>
              <span className="text-secondary">{state?.state_name}</span>
            </a>
          </h6>
        </div>

        {category !== "Tanah" && (
          <div className="d-flex gap-3 flex-grow-1">
            {bedroom !== null && (
              <span
                className="align-items-center rounded-2 p-1 flex-grow-1 d-flex gap-2 justify-content-center text-secondary"
                style={{ background: "#F5F5F5" }}
              >
                <div>
                  <Image
                    src="/facility/bedroom-new.svg"
                    alt="bedroom icon"
                    width="14px"
                    height="14px"
                  />
                </div>

                <p className="mb-0 d-flex gap-1" style={{ fontSize: "14px" }}>
                  {kFormatter(bedroom)}{" "}
                  <span className="d-none d-md-block">Bedroom</span>
                </p>
              </span>
            )}

            {(bedroom !== null || bathroom !== null) && (
              <span className="vr"></span>
            )}

            {bathroom !== null && (
              <span
                className="align-items-center rounded-2 p-1 flex-grow-1 d-flex gap-2 justify-content-center text-secondary"
                style={{ background: "#F5F5F5" }}
              >
                <div>
                  <Image
                    src="/facility/bathroom-new.svg"
                    alt="bathroom icon"
                    width="14px"
                    height="14px"
                  />
                </div>

                <p className="mb-0 d-flex gap-1" style={{ fontSize: "14px" }}>
                  {kFormatter(bathroom)}{" "}
                  <span className="d-none d-md-block">Bathroom</span>
                </p>
              </span>
            )}
          </div>
        )}
      </div>

      <hr className="my-0" />

      <div className="card-body d-block d-sm-flex gap-2 justify-content-between align-items-center">
        <h3
          className="card-text fs-5 text-primary mb-0"
          style={{ fontWeight: "600" }}
        >
          <CurrencyFormat amount={price} />
        </h3>

        <ListingAction
          type={2}
          id={id}
          user={user}
          listingUrl={listingUrl}
          name={product_name}
        />
      </div>
    </div>
  );
};

ListingItem.defaultProps = {
  id: 0,
  cover_file: { sizes: { thumb: "" } },
  district: { district_name: "default" },
  state: { state_name: "default" },
  area: { area_name: "default" },
  bathroom: "0",
  bedroom: "0",
  media: [],
  product_name: "",
  date: 0,
  // id: 0,
  user: {},
  price: 0,
  onClick: () => {},
  photoHeight: "150px",
  searchTrackingId: "",
  searchTrackingQuery: "",
};

export default ListingItem;

function timeSince(date) {
  var seconds = Math.floor(new Date().getTime() / 1000 - date),
    interval = Math.floor(seconds / 31536000);

  if (interval > 1) return interval + "th";

  interval = Math.floor(seconds / 2592000);
  if (interval > 1) return interval + "bln";

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return interval + "hr";

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return interval + "j";

  interval = Math.floor(seconds / 60);
  if (interval > 1) return interval + "m ";

  return Math.floor(seconds) + "d";
}
